import { getEnv } from 'config/env'
import { createRoot } from 'react-dom/client'

const initUi = () => {
	getEnv().then(async () => {
		/**
		 * lazy initialize App after ENV is ready
		 */
		const App = await import('App')

		createRoot(document.getElementById('root')!).render(<App.default />)
	})
}

window.addEventListener('DOMContentLoaded', initUi)
