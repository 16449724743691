import axios from 'axios'

export let ENV = {
	ENV: 'local', // 'local' | 'development' | 'sandbox' | 'production'
	IS_PRODUCTION: false,
	GTM_ID: '',
	FX_API_URL: '',
	KEYCLOAK_DOMAIN: '',
	KYC_STANDALONE_URL: '',
	LANDING_PAGE_URL: '',
}

// Get environment variables
export const getEnv = async (): Promise<void> => {
	const { data } = await axios.get('/config.json')
	ENV = { ...data }
}
